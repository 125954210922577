import { FLAGS, useFeatureFlag } from 'utils/launchdarkly';
import { NetworkStatusEnumType } from 'graphql-codegen/types';

export const networkStatusOptions = [
  { label: 'Pending', value: NetworkStatusEnumType.Pending },
  { label: 'Submitted', value: NetworkStatusEnumType.Submitted },
  { label: 'Approved', value: NetworkStatusEnumType.Approved },
  { label: 'Disabled', value: NetworkStatusEnumType.Disabled },
  { label: 'Rejected', value: NetworkStatusEnumType.Rejected },
  { label: 'Quality Hold', value: NetworkStatusEnumType.QualityHold },
  { label: 'General Hold', value: NetworkStatusEnumType.GeneralHold },
  { label: 'No New Patients', value: NetworkStatusEnumType.NoNewPatients },
];
